@font-face {
  font-family: "Manrope";
  src: url(./assets/fonts/Manrope-Regular.ttf);
}
* {
  font-family: "Manrope", sans-serif;
}
.mandatory {
  color: red;
  margin-left: 2px;
}
p {
  font-family: Manrope;
  /* font-size: 14px; */
  /* font-weight: 600; */
  /* line-height: 18px; */
  letter-spacing: 0em;
  text-align: left;
  /* color: #9c9c9c; */
  margin-bottom: 1px !important;
}
.bestValues{
  color:#FFFFFF;
  background-color: #006AFF;
  font-size: 10px;
  font-weight:500;
  height:20px;
  border-radius: 50px;
  padding: 4px 8px 4px 8px;
  margin-left: 5px;
}
label::first-letter {
  text-transform: uppercase !important;
}
.css-1dimb5e-singleValue {
  color: #031227 !important;
  font-weight: 500;
}
label {
  font-size: 14px!important;
  font-weight: 500!important;
  line-height: 20px;
  color: #1C2A3D !important;
  font-family: "Manrope";
  /* text-transform: capitalize; */
}
.Toastify__toast-container--top-right {
  top: 2em !important;
}

.Toastify__toast-container {
  z-index: 10000 !important;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.breadcrumds {
  color: #9c9c9c;
  font-size: 14px;
  font-weight: 400;
}
.modulesHeading::first-letter {
  text-transform: uppercase !important;
}
.modulesHeading {
  color: #031227;
  /* text-transform: capitalize; */
  font-size: 21px;
  font-weight: 600;
  line-height: 28px;
  margin-top: 10px;
  margin-bottom: 8px;
}
.ModuleArea {
  border: 1px solid #eeeff2;
  height: 81vh;
  margin-top: 16px;
  width: 100%;
  border-radius: 8px;
  background-color: white;
}
.ModuleArea2 {
  border: 1px solid #eeeff2;
  height: 80vh;
  margin-top: 15px;
  width: 100%;
  border-radius: 8px;
  background-color: white;
}
.listingContainer {
  width: 97%;
  margin: 0 auto;
}
.customButton {
  min-width: 100px;
  max-width: 200px;
  padding: 10px 10px;
  height: 44px;
  background-color: #006aff;
  border: none;
  border-radius: 8px;
  margin-left: 10px;
  color: #ffffff;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  /* letter-spacing: 1px; */
}
.customButtonDisable {
  min-width: 100px;
  max-width: 200px;
  padding: 10px 10px;
  height: 44px;
  background-color: #CCE1FF;
  border: none;
  border-radius: 8px;
  margin-left: 10px;
  color: #ffffff;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  cursor: not-allowed;
  /* letter-spacing: 1px; */
}
.fieldArea {
  height: 95px;
}
.error-message {
  color: #e72e55;
  font-size: 12px;
  font-weight: 600;
  /* letter-spacing: 0.5px; */
}
.tableBadge {
  width: 140px !important;
  height: 30px !important;
  font-size: 14px;
  color: #006aff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 16px;
  font-weight: 500;
  line-height: 20px;
  text-align: center;
  background-color: #f0f6ff;
}
.backArrow {
  margin-right: 10px;
}
.ticketBadge {
  width: 100px !important;
  height: 30px !important;
  font-size: 14px;
  color: #006aff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 16px;
  font-weight: 500;
  line-height: 20px;
  text-align: center;
  background-color: #f0f6ff;
}
.actionIcon {
  cursor: pointer;
}
.tableImage {
  width: 32px;
  height: 32px;
}

.container .jodit-status-bar {
  display: none;
}
.toasterr .Toastify__toast-container {
  width: 380px;
  height: 64px;
}
.tagword {
  text-transform: capitalize;
}
.Toastify__toast-body {
  align-items: start !important;
}
.toasterr .Toastify__toast-body::first-letter {
  text-transform: capitalize !important;
}
.toasterr .Toastify__toast-container::first-letter {
  text-transform: capitalize !important;
}
.toasterr .Toastify__toast-body > div:last-child::first-letter {
  text-transform: capitalize !important;
}
.toasterr .Toastify__toast-body > div:last-child {
  margin-left: 15%;
  color: #031227 !important;
}
.toasterr .Toastify__toast-theme--light {
  border-radius: 12px;
  /* background-image: linear-gradient(to right, #F1FEF5 , white); */
}
.toasterr .Toastify__toast--success {
  background-image: linear-gradient(to right, #f1fef5, white, white);
}
.toasterr .Toastify__toast--error {
  background-image: linear-gradient(to right, #fef5f4, white, white);
}
.toasterr .Toastify__toast-body > div:last-child .mainText {
  color: #031227 !important;

  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
}
.toasterr .Toastify__toast-body > div:last-child .subText {
  color: #4f5968 !important;

  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
}
.toasterr .Toastify__toast-icon {
  width: 18px;
}
.toasterr .Toastify__toast-icon img {
  margin-right: -8%;
}
::placeholder {
  text-transform: none !important;
}
/* *******************************text editor********************************************* */
.jodit-add-new-line {
  display: none !important;
}

.jodit_sticky > .jodit-toolbar__box {
  position: relative !important;
}
.jodit-status-bar {
  display: none !important;
}
.detailpageTitle {
  font-size: 20px;
  font-weight: 600;
}

.explanation-details td,
.explanation-details th {
  border: 1px solid #031227;
  padding: 5px;
}
.explanation-details td img{
  min-width: auto !important;
  min-height: auto !important;
  max-width: auto !important;
  max-height: auto !important;
  width: auto !important;
}
.explanation-details img {
  max-width: 600px !important;
  max-height: 300px !important;
  min-width: 175px !important;
  min-height: 175px !important;
  width: auto;
  /* height: 250px; */
  object-fit: cover;
  aspect-ratio: auto !important;
}
.explanation-details::first-letter {
  text-transform: uppercase;
}
/* ******************************************************** */
@media (min-width: 1024px) and (max-width: 1200px) {
.tableBadge {
  width: 110px !important;

  font-size: 11px;
}
/* .breaadcrumb-text{
  padding-top:0px
  
  } */
}

@media (min-width: 1024px) and (max-width: 1450px) {
  .tableBadge {
    width: 122px !important;

    font-size: 12px;
  }
}
.ck-editor__editable {
  min-height: 405px !important;
}

.DivBorder {
  border: 1px solid #e6e7e9;
  padding: 15px;
  border-radius: 8px;
  margin-bottom: 10px;
}
.DivBorder label {
  color: #68717d;

  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}
.ticketBadege {
  background-color: #f0f6ff;
  width: auto !important;
  border-radius: 16px;
  height: 30px;
  padding: 0px 10px;

  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: center;
  color: #006aff;
  display: flex;
  align-items: center;
  justify-content: center;
}
.ck-editor__editable p {
  font-weight: 400;
  /* line-height: 20px; */
}
.ticketBadege {
  background-color: #f0f6ff;
  width: auto !important;
  border-radius: 16px;
  height: 30px;
  padding: 0px 10px;

  font-size: 14px;
}
.tableTopText {
  font-size: 14px;
  color: #4f5968;
  font-weight: 400;
}
.reasonDiv {
  background-color: #f6f7f9;
  border-radius: 8px;
  padding: 10px;
  width: fit-content !important;
  max-width: 100% !important;
}
.circular-img {
  width: 34px;
  height: 34px;
  border-radius: 50%;
}
.infoLabel {
  color: #68717d !important;

  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 20px;
}
.reasoncontainer {
  border: 1px solid #e6e7e9;
  padding: 10px;
  border-radius: 8px;
}
.ck-list {
  max-height: 200px !important;
  max-width: 80px !important;
  overflow-y: auto;
  overflow-x: hidden;
  display: none;
}
.ck-list .ck-list__item .ck-button__label {
  font-size: 14px !important;
}

.TabSection {
  border-bottom: 1px solid #f1f1f1;
  cursor: pointer;
}
.inclined-border {
  position: relative;
  color: #2a85ff;
  padding-bottom: 10px;
  margin-right:16px !important;
}

.inclined-border::before,
.inclined-border::after {
  content: "";
  position: absolute;
  bottom: -2px;
  width: 100%;
  height: 4px;
  background: #2a85ff;
}

.inclined-border::before {
  border-radius: 50px;
}

.inclined-border::after {
  border-radius: 50px;
}

.detailDiv {
  background-color: #ffffff;
}
.rounded-img {
  border-radius: 50%;
}

.explanation-details {
  font-size: 14px;
  color: #212326;
  overflow-x: scroll; 
}


.explanation-details::-webkit-scrollbar {
  display: none;
}


.explanation-details {
  scrollbar-width: none; 
  -ms-overflow-style: none; 
}

.explanation-details .image {
  text-align: center;
}
.explanation-details .table table {
  width: 100% !important;
}
.htmlcontent .table td,
.htmlcontent .table th {
  border: 1px solid #e6e7e8;
  padding: 5px;
}

.slick-list .slick-slide .slick-active{
  width:50px
}