/* Basic styling */

:root {
  /* Colors */
  --black: #09090c;
  --grey: #a4b2bc;
  --white: #fff;
  --background: #ebedef;
}

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.table {
  background-color: inherit !important;
  /* Other styles for the table */
}
.table {

  --bs-table-bg:inherit !important;
}
body {
  /* background-color: var(--background); */
  font-family: "manrope,sans serf";
  overflow-x: hidden;
}

::-webkit-scrollbar {
  height: 5px;
  width: 5px;
  overflow: hidden;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #006aff;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #006aff;
}
